import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	kinoH1: {
		color: '#032836',
		fontSize: "5rem",
		fontWeight: "450 !important",
		display: "flex",
		justifyContent: "center",
		[theme.breakpoints.down("md")]: {
			fontSize: "3.5rem",
		},
		[theme.breakpoints.down("xs")]: {
			textAlign: "center",
			fontSize: "2rem"
		},
	},
}));

const Title = ({ text }) => {
	const classes = useStyles();
	return (
		<div>
			<h2 className={classes.kinoH1}>{text}</h2>
		</div>
	);
};

export default Title;
