import React, { useState, useRef, useEffect } from "react";
import {Helmet} from "react-helmet";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import * as rdd from "react-device-detect";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import AppleAuth from "../../../components/auth/AppleAuth";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import bgImage from "../../../assets/images/bottom-bg1.svg";

import "./SignUp.css";

const kinoTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(3, 40, 54)",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  singUp: {
    backgroundColor: "#fefefa",
    border: "4px solid rgb(247, 165, 80)",
    borderRadius: "18px",
    padding: "2rem",
    margin: "0rem auto",
    maxWidth: "650px",
    position: "relative",
    height: "750px",
    [theme.breakpoints.down("sm")]: {
      border: "none",
      padding: "0rem !important",
      marginBottom: "3rem"
    },
  },
  singUpContent: {
    maxWidth: "850px",
    margin: "0 auto",
  },
  paper: {
    maxWidth: "500px",
  },
  dialog: {
    backgroundColor: "#f8f8f8",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldSm: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flex: 1,
  },
  textFieldCustom: {
    flex: 1,
  },
  title: {
    fontSize: "3.5rem",
    textAlign: "center",
    color: "var(--darkColor)",
    lineHeight: "1.2",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
  },
  titleSm: {
    fontSize: "2.2rem",
    textAlign: "center",
    fontWeight: 500,
    color: "var(--darkColor)",
    fontWeight: "500",
    width: "85%",
    margin: "auto",
    marginBottom: "2rem",
    marginTop: "3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
      marginTop: "2rem",
    },
  },
  terms: {
    paddingBottom: "0.3rem",
    marginLeft: "-1.2rem",
    maxWidth: "100%",
    lineHeight: "1.5rem",
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "var(--darkColor)",
    color: "var(--grey)",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "var(--darkColor)",
      boxShadow: "none",
    },
    marginTop: "1rem",
  },
  link: {
    paddingTop: "1rem",
    width: "100%",
    textAlign: "center",
    color: "#032836",
    marginTop: "2rem",
    fontSize: "1.2rem !important",
  },
  formError: {
    marginBottom: "0.5rem",
    fontSize: "14px",
    color: "red",
    paddingLeft: "8px",
    "&::before": {
      content: "⚠ ",
    },
  },
  sign: {
    color: "var(--darkColor)",
    fontSize: "1rem",
    fontWeight: 400,
  },
  mainError: {
    textAlign: "center",
    color: "red",
    fontWeight: "400",
  },
  alternateAuth: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "6px 8px",
    gap: "3rem",
    marginTop: "6rem",
    [theme.breakpoints.down("md")]: {
      gap: "3rem",
      marginTop: "4rem",
      display: "flex",
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("xs")]: {
      gap: "2rem",
    },
    // flexFlow: "column",
  },
  kinoAuth: {
    fontSize: "18px",
    margin: "1.5rem 8px",
    color: "#032836",
    textAlign: "center",
    borderBottom: "1px solid #808080",
    lineHeight: "0rem",
  },
  kinoAuthSpan: {
    backgroundColor: "#fff",
    padding: "0 1rem",
    whiteSpace: "nowrap",
  },
  errorGeneral: {
    padding: "10px",
    marginTop: "0",
    margin: "10px",
    backgroundColor: "#ff726f",
    color: "#fff",
    borderRadius: "4px",
    textAlign: "center",
  },
  form: {
    maxWidth: "500px",
    margin: "0 auto",
    marginTop: "3rem"
  },
  iconTitle: {
    textAlign: "center",
    fontSize: "1.2rem",
    marginTop: "1rem",
    fontWeight: 600,
  },
  termsPolicy: {
    textAlign: "center",
    paddingTop: "8rem",
    fontWeight: 500,
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
      paddingTop: "4rem",
    },
  },
  termsPolicyFull: {
    textAlign: "center",
    paddingTop: "4rem",
    fontWeight: 500,
    fontSize: "1rem",
  },
  ReCAPTCHA: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem"
  },
}));

const SignUp = ({ ...rest }) => {
  const [check, setCheck] = useState(false);
  const [timeZone, setTimeZone] = useState();
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [loading, setLoading] = useState(false);
  const recaptchaSiteKey = "6Lfny-EZAAAAABMPAt_enOTPLvydYO-7hxC05ri3";
  const [errorForm, setErrorForm] = useState("");
  const [promoCodeError, setPromoCodeError] = useState("");
  const [generalMsg, setGeneralMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signUpWithMail, setSignUpWithMail] = useState(false);

  console.log(signUpWithMail);
  let timer = null;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    // confirmPassword: "",
    promoCode: "",
    terms: "on",
  });

  const {
    firstName,
    lastName,
    email,
    password,
    // confirmPassword,
    promoCode,
    terms,
  } = formData;

  const { register, errors, handleSubmit, watch } = useForm({});
  const userPassword = useRef({});
  userPassword.current = watch("password", "");

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: `Bearer ${token}`,
    },
  };

  const classes = useStyles();

  const handleLoginOpen = () => {
    rest.setModalOpen(true);
  };

  const handleRegisterClose = () => {
    rest.setRegisterOpen(false);
    setRecaptchaValue(false);
    setErrorForm("");
  };

  const handleCheck = () => {
    setCheck(!check);
  };

  const switchRegisterLogin = () => {
    handleRegisterClose();
    handleLoginOpen();
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    rest.registerOpen === false && setLoading(false);
    daylights_savings();
  }, [rest.registerOpen]);

  const handleRecaptcha = (e) => {
    // console.log(e);
    setRecaptchaValue(e);
  };

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      [name]: value,
    });
    name === "email" && setErrorForm("") && setLoading(false);
  };

  const daylights_savings = () => {
    let date = new Date();
    let offset = moment(date).isDST();
    if (offset === true) {
      setTimeZone(moment().utcOffset() - 60);
    } else {
      setTimeZone(moment().utcOffset());
    }
  };

  // const checkTimezone = () => {
  //   daylights_savings();
  //   console.log(timeZone);
  // }

  const onSubmit = async (e) => {
    setLoading(true);
    timer = setTimeout(() => {
      setLoading(false);
    }, 20000);

    setRecaptchaValue(false);
    setLoading(true);

    await axios
      .post(
        "/register",
        {
          name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          // password_confirmation: confirmPassword,
          promo_code: promoCode,
          agree_terms: "on",
          utcOffset: timeZone,
          _token: token,
        },
        config
      )
      .then(
        (request) => {
          if (request.data.errors === undefined) {
            //success
            // console.log(request.data);
            // window.location.replace(`/email-unverified?email=${email}`);
            handleRegisterClose();
            rest.setVerifyModalOpen(true);
            rest.setVerifyEmail(email);
          } else {
            //something went wrong
            setRecaptchaValue(true);
            clearTimeout(timer);
            setLoading(false);
            setErrorForm("");
            setPromoCodeError("");
          }
        },
        (error) => {
          // console.error(error.response.data);
          // console.error(error.response.data.errors.email);
          if (error.response.data.errors.email) {
            setErrorForm(error.response.data.errors.email);
          }
          if (error.response.data.errors.promo_code) {
            setPromoCodeError(error.response.data.errors.promo_code);
          }
          setRecaptchaValue(true);
          clearTimeout(timer);
          setLoading(false);
        }
      );
  };

  const props = {
    generalMsg,
    setGeneralMsg,
  };

  return (
    <div className="signup-background">
      <Helmet>
        <title>Kino | Sign Up</title>
      </Helmet>
      <div className={`${classes.singUp} signUp`}>
        <ThemeProvider theme={kinoTheme}>
          <div className={classes.singUpContent}>
            <p className={classes.title}>Inspiration starts here</p>
            {/* <p className={classes.titleSm}>
              Get your Kino account with the flavor of choice
            </p> */}
            {!signUpWithMail && (
              <p className={classes.titleSm}>Connect Kino with my</p>
            )}
            {generalMsg ? (
              <div className={classes.errorGeneral}>{generalMsg}</div>
            ) : (
              ""
            )}
            {!signUpWithMail && (
              <div className={classes.alternateAuth}>
                {/* Sign up with google */}
                <div>
                  <div
                    className="auth-provider-sign-up-page google-login-sign-up-page"
                    onClick={() => {
                      if(window.gtag_signup_conversion){
                        window.gtag_signup_conversion('google')
                      }
                      window.location.replace("/signin/google");
                    }}
                  >
                    <svg
                      aria-hidden="true"
                      className="svg-icon"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <g>
                        <path
                          d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z"
                          fill="#4285F4"
                        ></path>
                        <path
                          d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z"
                          fill="#34A853"
                        ></path>
                        <path
                          d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z"
                          fill="#FBBC05"
                        ></path>
                        <path
                          d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z"
                          fill="#EA4335"
                        ></path>
                      </g>
                    </svg>
                    {/* Sign up with Google */}
                  </div>
                  <p className={classes.iconTitle}>Google</p>
                </div>
                {/* Sign up with facebook */}
                <div>
                  <div
                    className="auth-provider-sign-up-page facebook-login-sign-up-page"
                    onClick={() => {
                      if(window.gtag_signup_conversion){
                        window.gtag_signup_conversion('facebook')
                      }
                      window.location.replace("/signin/facebook");
                    }}
                  >
                    <svg
                      width="534"
                      height="531"
                      viewBox="0 0 534 531"
                      fill="#1877F2"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M533.667 267C533.667 119.724 414.276 0.333344 267 0.333344C119.724 0.333344 0.333344 119.724 0.333344 267C0.333344 400.101 97.8494 510.422 225.333 530.427V344.083H157.625V267H225.333V208.25C225.333 141.417 265.145 104.5 326.057 104.5C355.233 104.5 385.75 109.708 385.75 109.708V175.333H352.124C318.997 175.333 308.667 195.889 308.667 216.978V267H382.625L370.802 344.083H308.667V530.427C436.151 510.422 533.667 400.101 533.667 267"
                        fill="#1877F2"
                      />
                    </svg>
                    {/* Sign up with Facebook */}
                  </div>
                  <p className={classes.iconTitle}>Facebook</p>
                </div>
                <div>
                  <AppleAuth
                    {...rest}
                    {...props}
                    type={"signup"}
                    // buttonName="Sign up with Apple"
                    method={"signup"}
                  />
                  <p className={classes.iconTitle}>Apple</p>
                </div>
                <div>
                  <div
                    className="auth-provider-sign-up-page email-login-sign-up-page"
                    onClick={() => setSignUpWithMail(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z" />
                    </svg>
                    {/* Sign up with Facebook */}
                  </div>
                  <p className={classes.iconTitle}>Email</p>
                </div>
              </div>
            )}

            {/* <div className={classes.kinoAuth}>
              <span className={classes.kinoAuthSpan}>
                or sign up with your&nbsp;email
              </span>
            </div> */}

            {signUpWithMail && (
              <form
                onSubmit={(e) => e.preventDefault()}
                autoComplete="off"
                className={classes.form}
              >
                <div className={classes.container}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    type="text"
                    variant="outlined"
                    inputProps={{ maxlength: 50 }}
                    className={classes.textFieldSm}
                    onChange={(e) => handleChange(e)}
                    inputRef={register({
                      required: "First name is required",
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Invalid first name",
                      },
                    })}
                  />

                  <TextField
                    margin="dense"
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    type="text"
                    variant="outlined"
                    inputProps={{ maxlength: 50 }}
                    className={classes.textFieldSm}
                    onChange={(e) => handleChange(e)}
                    inputRef={register({
                      required: "Last name is required",
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Invalid last name",
                      },
                    })}
                  />
                </div>
                <div className={classes.container}>
                  <div className={classes.textFieldCustom}>
                    {errors.firstName && (
                      <p className={classes.formError}>
                        {"⚠ "}
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div className={classes.textFieldCustom}>
                    {errors.lastName && (
                      <p className={classes.formError}>
                        {"⚠ "}
                        {errors.lastName.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className={classes.container}>
                  <TextField
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    onChange={(e) => handleChange(e)}
                    inputRef={register({
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <p className={classes.formError}>
                      {"⚠ "}
                      {errors.email.message}
                    </p>
                  )}
                  {errorForm ? (
                    <p className={classes.formError}>
                      {"⚠ "}
                      {errorForm}
                    </p>
                  ) : (
                    ""
                  )}
                  <TextField
                    margin="dense"
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => handleChange(e)}
                    inputRef={register({
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters",
                      },
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                        message:
                          "Password must include a combination of uppercase, lowercase letters and numbers.",
                      },
                    })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.password && (
                    <p className={classes.formError}>
                      {"⚠ "}
                      {errors.password.message}
                    </p>
                  )}
                  {/* <TextField
								margin="dense"
								id="confirmPassword"
								name="confirmPassword"
								label="Confirm Password"
								type="password"
								fullWidth
								className={classes.textField}
								variant="outlined"
								autoComplete="off"
								// value={confirmPassword}
								// onChange={e => setConfirmPassword(e.currentTarget.value)}
								onChange={(e) => handleChange(e)}
								inputRef={register({
									validate: (value) =>
										value === userPassword.current ||
										"⚠ Passwords do not match",
								})}
							/>
							{errors.confirmPassword && (
								<p className={classes.formError}>
									{errors.confirmPassword.message}
								</p>
							)} */}
                  <TextField
								margin="dense"
								id="promoCode"
								name="promoCode"
								label="Promo Code (optional)"
								type="text"
								fullWidth
								className={classes.textField}
								variant="outlined"
								autoComplete="off"
								onChange={(e) => handleChange(e)}
							/>
							{promoCodeError&& (
								<p className={classes.formError}>
									{promoCodeError}
								</p>
							)}
                </div>
                {/* <div
                  className={classes.container}
                  style={{
                    flexWrap: "nowrap",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={check}
                        onClick={handleCheck}
                        name="terms"
                        color="primary"
                        className={classes.textField}
                        onChange={handleCheck}
                        value={check}
                        inputRef={register({
                          required: "Please accept the Terms & Conditions",
                        })}
                      />
                    }
                  />
                  <p className={classes.terms}>
                    I accept the{" "}
                    <span>
                      <a href="/terms" onClick={handleRegisterClose}>
                        Terms&nbsp;&amp;&nbsp;Conditions
                      </a>
                    </span>{" "}
                    and{" "}
                    <span>
                      <a href="/privacy" onClick={handleRegisterClose}>
                        Privacy&nbsp;Policy
                      </a>
                    </span>
                    . <br />I confirm that I'm older than 18 years.
                  </p>
                  {errors.terms && (
                    <p className={classes.formError}>
                      {"⚠ "}
                      {errors.terms.message}
                    </p>
                  )}
                </div> */}
                <div className={classes.ReCAPTCHA}>
                  <div className={classes.textField}>
                    {!rdd.isMobile ? (
                      <ReCAPTCHA
                        sitekey={recaptchaSiteKey}
                        onChange={(e) => handleRecaptcha(e)}
                        // onErrored={e => console.log(e)}
                        className={classes.recaptcha}
                      />
                    ) : (
                      <ReCAPTCHA
                        sitekey={recaptchaSiteKey}
                        onChange={(e) => handleRecaptcha(e)}
                        // onErrored={e => console.log(e)}
                        className={classes.recaptcha}
                        size="compact"
                      />
                    )}
                    {errors.recaptcha && (
                      <p className={classes.formError}>
                        {"⚠ "}
                        {errors.recaptcha.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className={classes.container}>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    fullWidth
                    className={classes.button}
                    disabled={!recaptchaValue}
                    type="submit"
                  >
                    {!loading && <span>Create Account</span>}
                    {loading && (
                      <div>
                        <CircularProgress
                          style={{
                            height: "17px",
                            width: "17px",
                            marginRight: "5px",
                            color: "#032836",
                            verticalAlign: "middle",
                          }}
                        />
                        &nbsp;Please wait
                      </div>
                    )}
                  </Button>
                </div>
              </form>
            )}
            <div>
              {!signUpWithMail ? (
                <p className={classes.termsPolicy}>
                  By creating Kino account you accept to kino{" "}
                  <a href="/terms">{`Terms & Conditions`}</a> and{" "}
                  <a href="/privacy">Privacy Policy</a>. By proceeding I conform
                  I am 18 years of age or older.
                </p>
              ) : (
                <p className={classes.termsPolicyFull}>
                  By creating Kino account you accept to kino{" "}
                  <a href="/terms">{`Terms & Conditions`}</a> and{" "}
                  <a href="/privacy">Privacy Policy</a>. By proceeding I conform
                  I am 18 years of age or older.
                </p>
              )}
            </div>
            <div className={classes.container}>
              <p className={classes.link}>
                Already a member?{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="/signin"
                    style={{
                      textDecoration: "underline",
                      color: "#032836",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    SIGN IN
                  </a>
                </span>
              </p>
            </div>
          </div>
        </ThemeProvider>
      </div>
      <img src={bgImage} className="bg-image"></img>
    </div>
  );
};

export default SignUp;
