import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { Typography, Container } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import { Link } from "@material-ui/core";
import Title from "../Components/Title";
import WhatTheyAsk from "../Images/WhatTheyAsk.png";
import WhatTheyAskCircle from "../Images/WhatTheyAskCircle.png";

import "./Faq.css"

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		padding: "80px 0",
		overflow: 'hidden'
	},

	title: {
		fontSize: "2.5rem",
		fontWeight: "bold",
	},
	heading: {
		fontSize: "1.1rem",
		// padding: "22px 32px",
		margin: "0",
		fontWeight: "600",
		[theme.breakpoints.down("sm")]: {
			padding: "22px",
		},
		[theme.breakpoints.down("xs")]: {
			padding: "16px 10px",
			fontSize: "1rem",
		},
	},
	description: {
		fontSize: theme.typography.pxToRem(15),
		padding: "0 12px",
		margin: "0",
		fontWeight: "400",
		lineHeight: '30px !important',
		[theme.breakpoints.down("sm")]: {
			padding: "0px 22px",
			fontWeight: "300",
		},
		[theme.breakpoints.down("xs")]: {
			fontSize: "0.9rem",
			padding: "0px 10px",
		},
	},
	footer: {
		fontWeight: "600",
		textTransform: "unset",
		padding: "11px 36px",
		maxWidth: "513px",
		[theme.breakpoints.down("xs")]: {
			padding: "11px 0px",
		},
	},
	button: {
		backgroundColor: "#032836",
		// width: "200px",
		fontSize: "18px",
		textTransform: "none",
		// borderRadius: "10px",
		"&:hover": {
			backgroundColor: "rgb(3, 40, 54)",
		},
		// [theme.breakpoints.down("xs")]: {
		// 	width: '100%',
		// },
	},
	subTitle: {
		fontWeight: "500",
		lineHeight: "2",
		// fontStyle: "italic",
		textAlign: "center",
		// marginLeft: "33px",
		[theme.breakpoints.down("sm")]: {
			// textAlign: "center",
			// marginLeft: "0",
			// marginBottom: "5px",
		},
	},
	questionStyle: {
		textAlign: "center",
		[theme.breakpoints.down("sm")]: {
			width: "67%",
			margin: "auto",
			marginBottom: "10px",
			padding: "0px 15px",
			lineHeight: '1.2'
		},
	},
	accordion:{
		[theme.breakpoints.down("sm")]: {
			padding: '0'
		}
	},
	WhatTheyAsk:{
		position: 'absolute',
		height: '60px',
		right: '16px',
		top: '0px',
		zIndex: '-1',
		transition: 'all 0.3s',
		[theme.breakpoints.down("sm")]: {
			right: '20px',
			top: '-13px',
			height: '80px'
		}
	},
	WhatTheyAskLeft:{
		position: 'absolute',
		height: '400px',
		left: '-200px',
		bottom: '50px',
		[theme.breakpoints.down("sm")]: {
			display: 'none'
		}
	},
	WhatTheyAskRight:{
		position: 'absolute',
		right: '-200px',
		height: '400px',
		top: '-50px',
		[theme.breakpoints.down("sm")]: {
			display: 'none'
		}
	},
	maxWidth420px:{
		maxWidth: '420px',
		
	},
}));

const faqs = [
	{
		heading: "Is this a virtual HQ?",
		description: `Kino has spaces that keep you connected, audible and visible unless made private. <br/>Zoom has breakout-rooms that separates you from others. That’s not all… Enter our showroom above and start exploring.  `
	},{
		heading: "How is this different than Zoom?",
		description: `Zoom is good for business calls, Kino is good for that too, but also for everything else. <br/>Online collaboration, education or just being with friends.  `
	},{
		heading: "I’m using Zoom , why do I need this?!",
		description: `Based on your plan you can have 10, 100 or even 200 people on your Kino. Need more? Reach out at <a href="mailto:team@kino.live">team@kino.live</a>.`,
	},{
		heading: "I don't like to be on camera, can i still use this?",
		description:"Simply use the invite button when in a Kino or simply share the URL of your Kino.",
	},{
		heading: "How many people can join a Kino?",
		description:"After you sign up, click on the SCHEDULE button on the top right corner. You can also assign moderators ahead of time.",
	},{
		heading: "How can i invite others to join my Kino?",
		description:"Click on the menu (3 dots) icon at the bottom right of the screen to see all the features of Kino.",
	},{
		heading: "How do i change my virtual background?",
		description:"You can cancel your subscription at any time on your profile under billing section.",
	},{
		heading: "How do I schedule a Kino?",
		description:"You can cancel your subscription at any time on your profile under billing section.",
	},
];

const Faq = () => {
	const classes = useStyles();

	// const [expanded, setExpanded] = React.useState(false);

	// const handleChange = (panel) => (event, isExpanded) => {
	//     setExpanded(isExpanded ? panel : false);
	// };

	return (
		<div className={classes.root + ` test`} id="faq">
			<Container maxWidth="lg" style={{position: 'relative'}}>
				<img src={WhatTheyAskCircle} alt="" className={classes.WhatTheyAskLeft}/>
				<img src={WhatTheyAskCircle} alt="" className={classes.WhatTheyAskRight}/>
				<Container className={classes.maxWidth420px}>
					<span style={{position: 'relative', display: 'flex', justifyContent: 'center'}}>
						<Title text={"What they ask"} />
						<img src={WhatTheyAsk} alt="what they ask dot" className={classes.WhatTheyAsk}/>
					</span>
					{/* </Typography> */}
				</Container>
				<Container className="accordion-faq">
					{faqs.map((faq, i) => (
						<Accordion key={i} className="remove-spacing">
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								className={classes.accordion}
							>
								<Typography className={classes.heading}>{faq.heading}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography
									className={classes.description}
									dangerouslySetInnerHTML={{ __html: faq.description }}
								></Typography>
							</AccordionDetails>
						</Accordion>
					))}
				</Container>
				<Container maxWidth="md" className={classes.footer}>
					<Typography
						variant="h6"
						className={classes.questionStyle}
					>
						Didn't find your question? Ask us directly
					</Typography>
					<Typography variant="subtitle2" className={classes.subTitle}>
						That Kino Team
					</Typography>
				</Container>
				<Container maxWidth="md" align="center">
					<Link href={"mailto:team@kino.live"} style={{ textDecoration: "none" }}>
						<Button
							className={(classes.footer, classes.button)}
							variant="contained"
							color="primary"
						>
							Ask a question{" "}
						</Button>
					</Link>
				</Container>
			</Container>
		</div>
	);
};

export default Faq;
