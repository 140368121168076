import React, { useState } from 'react';
import { Grid, Typography, Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GreenDesk from '../Images/Greendesk.png';
import NomadWorks from '../Images/nomadworks.svg';
import DeskStash from '../Images/desk-stash.png';
import TeamClass from '../Images/teamclass.svg';
import Belay from '../Images/belay.svg';
import MailBox from '../Images/mailbox.png';
import DeskPass from '../Images/deskpass.svg';
import IdeaIllinois from '../Images/ideaillinois.svg';
import gsdSolutions from '../Images/gsdSolutions.png';
import EADL from '../Images/EADL.jpg';

import animationHeader1 from '../Images/wave2.png';
import animationHeader2 from '../Images/wave1.png';

import './Partnership.css';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  partnership: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  hero: {
    position: 'relative',
    minHeight: '50vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    color: '#ffffff',
    background: 'url(/newImages/partnership.jpg) center',
    '&::before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      backgroundColor: '#072531',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      zIndex: '1',
    },
  },
  hero_text: {
    zIndex: '5',
    textAlign: 'center',
    color: '#ffffff',
    fontWeight: '500',
    [theme.breakpoints.down('xs')]: {
      fontSize: '3rem',
    },
  },
  heroDescription: {
    margin: '30px auto 0',
    maxWidth: '700px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  partnership_container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: 'var(--mainText)',
    padding: '32px 12px',
    margin: '30px 0 250px',
  },
  partner: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '78px',
    alignItems: 'center',
    marginBottom: '20px',
  },
  partner_img: {
    maxWidth: '250px',
    cursor: 'pointer',
    backgroundColor: 'white',
    zIndex: '1',
    height: '60px',
    transition: '.3s cubic-bezier(0,1.06,.69,1.15)',
  },
  imgContainer: {
    zIndex: '1',
  },
  borderPartner: {
    width: '280px',
    minHeight: '320px',
    border: '1px solid black',
    position: 'absolute',
    borderRadius: '10px',
    zIndex: '2',
    backgroundColor: 'white',
    top: '37px',
    display: 'none',
    opacity: '0',
    transition: '.3s ease-in',
  },
  partner_description: {
    fontSize: '16px',
    padding: '40px 30px',
    width: '100%',
    height: '100%',
  },

  showPartnerDescFlex: {
    display: 'flex',
  },
  showPartnerDescOpacity: {
    opacity: '1',
  },
  lowerImageSize: {
    transition: '.3s cubic-bezier(0,.92,.75,1.29)',
    transform: 'scale(0.6)',
  },
  hideImage: {
    zIndex: '3',
  },
  helloMailboxHeight: {
    height: '30px',
  },
  EADLHeight: {
    height: '120px',
  },
  EADLPosition: {
    top: '74px',
  },
}));

const Partnership = () => {
  const classes = useStyles();
  const [showPartnerDesc, setShowPartnerDesc] = useState(false);
  const [showPartnerDesc2, setShowPartnerDesc2] = useState(false);
  const [lowerLogo, setLowerLogo] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);
  const [partnerIndex, setPartnerIndex] = useState(null);
  const [nextPartnerIndex, setNextPartnerIndex] = useState(null);
  var timer = null;

  const partners = [
    'Greendesk',
    'Nomadworks',
    'Desk-stash',
    'Teamclass',
    'Belaysolutions',
    'Hello-mailbox',
    'Deskpass',
    'ideaIllinois',
    'EADL',
  ];

  const partnersDesc = [
    'Greendesk is an expert in providing affordable and flexible office spaces. Available for month-to-month or long-term rental we are there for you to help take your business to the next level.',
    'Nomadworks is a premier coworking space in Manhattan. Our hospitality focused team fosters networking and connections for our members. Contact community@nomadworks.com for membership rates starting at $50/month for virtual space and discounted day passes for Kino users.',
    'A subscription box for fans of unique office supplies - delivered quarterly to your home or office. Featured on Buzzfeed\'s "20 Subscription Boxes That People Actually Swear By".Subscribe at www.DeskStash.com and use code KINO15 for 15% off your first box or yearly subscription.',
    'Top ranked virtual events from around the country featuring vetted hosts with kits included that ship nationwide. Mention Kino for 10% off your first event.',
    'We match busy business owners with virtual assistants. We are the experts in helping you get your time back. Focus on things that matter! Satisfaction Guaranteed. Personalized Solutions. Industry Leader. Flexible Contracts. U.S. Based Assistants.',
    "Delight your leads, customers and team by sending them real coffee. Kino + Hello Mailbox is the perfect combination to make your virtual meetings even more engaging. Use code KINO20 for 20% off your first Virtual Coffee order! For orders of more than 50 units, you'll get free branded stationery and packaging.",
    'We make hybrid work. Thousands of workspaces your team will love. Unlimited flexibility. No commitments. Pay as you go. Using the code KINOPARTNERPERK will give your members 10% off recurring, on our Mini, Part-Time, and Full-Time plans only.',
    'Inspire, connect and provide the educational community with opportunities that transform teaching and learning through technology.',
    'The Premier European Organization for Distance Learning Providers and Practitioners. The objective of EADL is to promote learning, wherever and however it occurs.',
  ];

  const partnersLinks = [
    'https://greendesk.com/',
    'https://nomadworks.com/',
    'https://www.deskstash.com/',
    'https://www.teamclass.com/',
    'https://belaysolutions.com/',
    'https://www.hello-mailbox.com/',
    'https://www.deskpass.com/',
    'https://ideaillinois.org/',
    'https://www.eadl.org/',
  ];

  const partnersImgs = [
    GreenDesk,
    NomadWorks,
    DeskStash,
    TeamClass,
    Belay,
    MailBox,
    DeskPass,
    IdeaIllinois,
    // Camo,
    EADL,
  ];

  const goToPage = (url) => {
    window.open(url, '_blank');
  };

  const handleOpenChange = (index) => {
    if (timer) {
      clearTimeout(timer);
    }
    setShowPartnerDesc(true);
    timer = setTimeout(() => {
      setShowPartnerDesc2(true);
    }, 200);
    setLowerLogo(true);
    setHideLogo(true);
    setPartnerIndex(index);
    setNextPartnerIndex(index);
  };
  const handleCloseChange = (index) => {
    if (timer) {
      clearTimeout(timer);
    }
    setShowPartnerDesc(false);
    timer = setTimeout(() => {
      setShowPartnerDesc2(false);
    }, 200);
    setLowerLogo(false);
    setHideLogo(false);
    setPartnerIndex(index);
    setNextPartnerIndex(index);
  };

  return (
    <Grid container className={classes.partnership}>
      <Helmet>
        <title>Kino Live | Our Partners</title>
      </Helmet>
      <Grid item className={classes.hero + ' hero-hover'}>
        <Container className={classes.hero_text}>
          {/* Animation Header */}
          <Box display={{ xs: 'none', md: 'block' }}>
            <img
              src={animationHeader1}
              className='animationHeader1'
              alt=''
            ></img>
            <img
              src={animationHeader2}
              className='animationHeader2'
              alt=''
            ></img>
          </Box>
          {/* /Animation Header */}
          <Typography component='h1' variant='h2' className={classes.hero_text}>
            Our Partners
          </Typography>
          <Typography
            component='p'
            variant='body1'
            className={classes.heroDescription}
          >
            Kino extends beyond just a more personable virtual chat experience.
            We're bringing together the real-life feel to the online experience.
            Our online community brings together virtual offices, networking
            opportunities, and virtual events + workshops alike.
          </Typography>
        </Container>
      </Grid>
      <Container maxWidth='lg'>
        <Grid
          container
          direction='row'
          spacing={3}
          className={classes.partnership_container}
        >
          {partners.map((p, i) => (
            <Grid
              item
              className={classes.partner}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onMouseLeave={() => handleCloseChange(i)}
              key={p}
            >
              <Box
                onMouseEnter={() => handleOpenChange(i)}
                onClick={() => goToPage(partnersLinks[i])}
                className={`${classes.imgContainer}
                ${
                  i === nextPartnerIndex
                    ? hideLogo
                      ? classes.hideImage
                      : ''
                    : ''
                }`}
              >
                <img
                  src={partnersImgs[i]}
                  alt={p}
                  className={`${classes.partner_img}
                ${
                  i === partnerIndex
                    ? lowerLogo
                      ? classes.lowerImageSize
                      : ''
                    : ''
                }
                ${p === 'Hello-mailbox' ? classes.helloMailboxHeight : ''}
                ${p === 'EADL' ? classes.EADLHeight : ''}
               `}
                />
              </Box>
              <Box
                className={`${classes.borderPartner}
                ${
                  i === partnerIndex
                    ? showPartnerDesc
                      ? classes.showPartnerDescFlex
                      : ''
                    : ''
                }
                ${
                  i === partnerIndex
                    ? showPartnerDesc2
                      ? classes.showPartnerDescOpacity
                      : ''
                    : ''
                }
                ${p === 'EADL' ? classes.EADLPosition : ''}
                `}
                onMouseLeave={() => handleCloseChange(i)}
              >
                <Typography
                  component='p'
                  variant='body1'
                  className={classes.partner_description}
                >
                  {partnersDesc[i]}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
};

export default Partnership;
