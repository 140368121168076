import React, { Fragment, useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PersonIcon from '@material-ui/icons/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@material-ui/icons/Close';
import * as rdd from 'react-device-detect';
import Button from '@material-ui/core/Button';
import Login from '../Modals/Login';
import Register from '../Modals/Register';
import VerifyEmail from '../Modals/VerifyEmail';
import SignUpSuccessful from '../Modals/CallToAction/SignUpSuccessful';
import Logo from '../../Views/NewKino/Images/kinologo.svg';
import Navigation from '../../Views/NewKino/Navbar/Navigation';
import ScrollToView from '../ScrollToTop/ScrollToView';
import NewHamburger from '../../Views/NewKino/Images/NewHamburger.svg';

import './Navbar.css';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: '#ededed',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'grey',
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  swipeableDraver: {
    'backdrop-filter': 'blur(5px) saturate(120%)',
    zIndex: '9000 !important ',
  },
  list: {
    width: '50vw',
    color: 'var(--DarkColor)',
    backgroundColor: 'transparent',
    Height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  button: {
    color: 'var(--darkColor)',
  },
  item: {
    marginTop: '1rem',
  },
  icons: {
    margin: '0',
    padding: '0',
    minWidth: '10px',
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px',
    cursor: 'pointer',
  },
  menuItem: {
    width: '120px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    height: '40px',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.down('xs')]: {
      width: '108px',
    },
  },
  signUpBtn: {
    color: '#ffff',
    backgroundColor: '#ff4746',
    borderRadius: '8px',
    padding: '10px 35px',
    marginLeft: '1.5rem',
    '&:hover': {
      backgroundColor: 'var(--darkColor)',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '0rem',
    },
  },
  signInBtn: {
    borderRadius: '8px',
    padding: '10px 35px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0rem',
    },
  },
  startKiko: {
    color: '#ffff',
    backgroundColor: '#ff4746',
    borderRadius: '8px',
    padding: '10px 21px',
    '&:hover': {
      backgroundColor: 'var(--darkColor)',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '0rem',
    },
  },
  rightElementNavbar: {
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },
}));

const Navbar = ({ ...rest }) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState({ right: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const [successModal, openSuccessModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const path = window.location.pathname == "/partnerships"
  const handleScroll = (event) => {
    if (window) {
      setScrollPosition(window.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  var firstName = `${rest.user.name}`;
  var firstNameLetters = '';
  var lastName = `${rest.user.last_name}`;
  var firstLetterLastName = '';

  if (firstName !== 'undefined') {
    if (firstName.length > 8) {
      firstNameLetters = `${firstName.substring(0, 8)}.`;
    } else {
      firstNameLetters = firstName;
    }
  } else {
    firstNameLetters = ' ';
  }

  if (lastName !== 'undefined') {
    firstLetterLastName = lastName.charAt(0) + '.';
  } else {
    firstLetterLastName = '';
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = async (e) => {
    e.preventDefault();
    document.getElementById('logout-form').submit();
  };

  const goToStore = () => {
    if (rdd.isIOS)
      window.location.href =
        'https://apps.apple.com/app/kino-live/id1522590532';
    if (rdd.isAndroid)
      window.location.href =
        'https://play.google.com/store/apps/details?id=io.kinetx.kino';
  };

  const createRoom = () => {
    window.location.href = '/createRoom';
  };

  const props = {
    successModal,
    openSuccessModal,
  };

  const guestLinks = (
    <ul className='guestLinks'>
      <li>
        <Button
          style={{
            color: 'var(--darkColor)',
          }}
          className={classes.signInBtn + ' signInBtn'}
          onClick={() => {
            rest.setModalOpen(true);
          }}
        >
          Sign in
        </Button>
      </li>
      <li>
        <Button
          className={classes.signUpBtn + ' signUpBtn'}
          onClick={() => history.push("/signup")}
        >
         Sign up
        </Button>
      </li>
    </ul>
  );

  const userLinks = (
    <ul className='userLinks'>
      <li className={classes.rightElementNavbar}>
        <Button
          disabled={rest.disableStartKino}
          className={classes.startKiko}
          onClick={() => {
            createRoom();
            rest.setDisableStartKino(true);
            setTimeout(() => {
              rest.setDisableStartKino(false);
            }, 10000);
          }}
        >
          {' '}
          {!rest.disableStartKino && 'Start a Kino'}
          {rest.disableStartKino && (
            <span>
              <CircularProgress
                style={{
                  height: '17px',
                  width: '17px',
                  marginRight: '5px',
                  color: 'var(--darkColor)',
                  verticalAlign: 'middle',
                }}
              />
              &nbsp;Please wait
            </span>
          )}
        </Button>
      </li>
      <li className={classes.rightElementNavbar}>
        <Button
          style={{
            fontSize: '13.5px',
            padding: '10px 21px',
          }}
        >
          <a
            href='/profile?schedule=true'
            style={{
              color: 'var(--darkColor)',
              textDecoration: 'none',
            }}
          >
            Schedule a Kino
          </a>
        </Button>
      </li>
      <li className={classes.rightElementNavbar}>
        <Button
          style={{
            color: 'var(--darkColor)',
            textDecoration: 'none',
            padding: '10px 21px',
          }}
          onClick={handleClick}
        >
          {firstNameLetters} {firstLetterLastName}
          <ListItemIcon className={classes.icons}>
            <ArrowDropDownIcon fontSize='small' />
          </ListItemIcon>
        </Button>
        <StyledMenu
          id='customized-menu'
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          autoFocus={false}
        >
          <a
            href='/profile'
            onClick={handleClose}
            style={{
              lineHeight: '2.5',
              color: 'var(--darkColor)',
              minHeight: '48px',
              textDecoration: 'none',
              display: 'flex',
            }}
          >
            <StyledMenuItem style={{ minWidth: 150 }}>
              <ListItemIcon className={classes.icons}>
                <PersonIcon fontSize='small' style={{ alignSelf: 'end' }} />
              </ListItemIcon>
              <span
                style={{
                  paddingLeft: '8px',
                  fontSize: '0.9rem',
                  fontWeight: '500',
                }}
              >
                Dashboard
              </span>
            </StyledMenuItem>
          </a>
          <StyledMenuItem>
            <ListItemIcon
              className={classes.icons}
              onClick={(event) =>
                (window.location.href = '/profile/billing')
              }
            >
              <ReceiptIcon fontSize='small' />
            </ListItemIcon>
            <a
              href='/profile/billing'
              className='nav_link'
              style={{
                lineHeight: '2.5',
                color: 'var(--darkColor)',
                textDecoration: 'none',
                paddingLeft: '8px',
                fontSize: '0.9rem',
                fontWeight: '500',
              }}
            >
              Billing
            </a>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={(e) => signOut(e)}
            style={{ paddingLeft: 20 }}
          >
            <ListItemIcon className={classes.icons}>
              <LogoutIcon fontSize='small' style={{ alignSelf: 'end' }}/>
            </ListItemIcon>
            <a
              href='/'
              className='nav_link'
              style={{
                lineHeight: '2.5',
                color: 'var(--darkColor)',
                textDecoration: 'none',
                paddingLeft: '8px',
                fontSize: '0.9rem',
                fontWeight: '500',
              }}
            >
              Sign out
            </a>
          </StyledMenuItem>
        </StyledMenu>
      </li>
    </ul>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
    rest.setMenuOpen(open);
  };

  const list = (anchor) => (
    <div className={classes.list} role='presentation'>
      <div
        className={classes.closeButton}
        onClick={toggleDrawer(anchor, false)}
      >
        <CloseIcon fontSize='large' />
      </div>
      <List>
        <ul className='burger_list'>
          <li onClick={toggleDrawer(anchor, false)}>
            <Button
              disabled={rest.disableStartKino}
              className={classes.scheduleKino}
              onClick={(e) => {
                rest.setDisableStartKino(true);
                setTimeout(() => {
                  rest.setDisableStartKino(false);
                }, 10000);
              }}
            >
              {!rdd.isMobile ? (
                <a href='/createRoom' className='start-kino-link'>
                  {!rest.disableStartKino && 'Start a Kino'}
                  {rest.disableStartKino && (
                    <span>
                      <CircularProgress
                        style={{
                          height: '17px',
                          width: '17px',
                          marginRight: '5px',
                          color: 'var(--darkColor)',
                          verticalAlign: 'middle',
                        }}
                      />
                      &nbsp;Please wait
                    </span>
                  )}
                </a>
              ) : (
                <a href='#' onClick={goToStore} className='start-kino-link'>
                  {!rest.disableStartKino && 'Start a Kino'}
                  {rest.disableStartKino && (
                    <span>
                      <CircularProgress
                        style={{
                          height: '17px',
                          width: '17px',
                          marginRight: '5px',
                          color: 'var(--darkColor)',
                          verticalAlign: 'middle',
                        }}
                      />
                      &nbsp;Please wait
                    </span>
                  )}
                </a>
              )}
            </Button>
          </li>
          <li onClick={toggleDrawer(anchor, false)}>
            <Button>
              <a
                href='/profile?schedule=true'
                style={{ color: 'var(--darkColor)' }}
              >
                Schedule a Kino
              </a>
            </Button>
          </li>
          <li
            className={classes.menuItem}
            onClick={toggleDrawer(anchor, false)}
          >
            <a href='/profile/dashboard'>
              <Button style={{ color: 'var(--darkColor)' }}>
                <PersonIcon fontSize='small' style={{ marginRight: '8px' }} />
                Dashboard
              </Button>
            </a>
          </li>
          <li
            className={classes.menuItem}
            onClick={toggleDrawer(anchor, false)}
          >
            <a
              href='/profile/billing'
              style={{ color: 'var(--darkColor)' }}
            >
              <Button style={{ color: 'var(--darkColor)' }}>
                <ReceiptIcon fontSize='small' style={{ marginRight: '8px' }} />
                Billing
              </Button>
            </a>
          </li>
          <li onClick={toggleDrawer(anchor, false)}>
            <Button
              style={{
                fontWeight: '500',
                color: 'var(--darkColor)',
              }}
              onClick={() => {
                ScrollToView('plans');
                history.push('/');
              }}
            >
              Pricing
            </Button>
          </li>
          <li onClick={toggleDrawer(anchor, false)}>
            <Button
              style={{
                fontWeight: '500',
                color: 'var(--darkColor)',
              }}
              onClick={() => {
                ScrollToView('downloads');
                history.push('/');
              }}
            >
              Downloads
            </Button>
          </li>
          <li onClick={toggleDrawer(anchor, false)}>
            <Button
              style={{
                fontWeight: '500',
                color: 'var(--darkColor)',
              }}
              onClick={() => {
                ScrollToView('about');
                history.push('/');
              }}
            >
              About
            </Button>
          </li>
          <li
            className={classes.menuItem}
            onClick={toggleDrawer(anchor, false)}
          >
            <Button>
              <NavLink
                to='/'
                className='nav_link'
                onClick={(e) => signOut(e)}
                style={{ color: 'var(--darkColor)' }}
              >
                <i
                  className='fas fa-sign-out-alt'
                  style={{
                    marginRight: '8px',
                    fontSize: '18px',
                  }}
                ></i>
                Sign out
              </NavLink>
            </Button>
          </li>
        </ul>
      </List>
    </div>
  );

  const listguests = (anchor) => (
    <div className={classes.list} role='presentation'>
      <div
        className={classes.closeButton}
        onClick={toggleDrawer(anchor, false)}
      >
        <CloseIcon fontSize='large' />
      </div>
      <List>
        <ul className='burger_list'>
          <li onClick={toggleDrawer(anchor, false)}>
            <Button
              onClick={() => {
                rest.setModalOpen(true);
              }}
              style={{
                fontWeight: '500',
                color: 'var(--darkColor)',
              }}
            >
              Sign in
            </Button>
          </li>
          <li onClick={toggleDrawer(anchor, false)}>
            <Button
              style={{
                fontWeight: '500',
                color: 'var(--darkColor)',
              }}
              onClick={() => history.push("/signup")}
            >
              Sign up
            </Button>
          </li>
          <li onClick={toggleDrawer(anchor, false)}>
            <Button
              style={{
                fontWeight: '500',
                color: 'var(--darkColor)',
              }}
              onClick={() => {
                ScrollToView('plans');
                history.push('/');
              }}
            >
              Pricing
            </Button>
          </li>
          <li onClick={toggleDrawer(anchor, false)}>
            <Button
              style={{
                fontWeight: '500',
                color: 'var(--darkColor)',
              }}
              onClick={() => {
                ScrollToView('downloads');
                history.push('/');
              }}
            >
              Downloads
            </Button>
          </li>
          <li onClick={toggleDrawer(anchor, false)}>
            <Button
              style={{
                fontWeight: '500',
                color: 'var(--darkColor)',
              }}
              onClick={() => {
                ScrollToView('about');
                history.push('/');
              }}
            >
              About
            </Button>
          </li>
        </ul>
      </List>
    </div>
  );

  const burger = (
    <div className='hamburger'>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            className={classes.button}
          >
            <img
              src={NewHamburger}
              alt='Hamburger Menu'
              style={{ width: '1.875em' }}
            />
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            className={classes.swipeableDraver}
          >
            {rest.isAuth !== 0 ? list(anchor) : listguests(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
  return (
    <div
      className={
        !scrollPosition
          ? !path
            ? "navbar-container"
            : "navbar-container-white"
          : "navbar-container-scroll"
      }
      id="container_scroll_y"
    >
      <VerifyEmail {...rest} />
      <Register {...rest} />
      <Login {...rest} />
      <SignUpSuccessful {...rest} {...props} />
      <nav className="navbar bg-dark">
        <div className="logo-n-button-container">
          <Link to="/">
            <img
              className={classes.logo}
              src={Logo}
              alt="Kino Live Logo"
              onClick={() => {
                ScrollToView("hero");
              }}
            />
          </Link>
        </div>
        <Box display={{ xs: "none", sm: "none", md: "flex" }}>
          <Navigation {...rest} />
        </Box>
        <Fragment>
          {rest.isAuth !== 0 ? (
            <Fragment>
              {userLinks}
              {burger}
            </Fragment>
          ) : (
            <Fragment>
              {guestLinks}
              {burger}
            </Fragment>
          )}
        </Fragment>
      </nav>
    </div>
  );
};

export default Navbar;
