import React from "react";
import "./Navbar.css";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ScrollToView from "../../../components/ScrollToTop/ScrollToView";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	button: {
		fontWeight: "500",
		color: "var(--darkColor)",
		fontSize: "13.5px",
		padding: "10px 21px",
		marginRight: "2rem",
	},
}));

const Navigation = ({ ...rest }) => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<div className="nav-navigation">
			<ul className="nav-list">
				{/* <li>
					<Button
						onClick={() => {
							ScrollToView("features");
							history.push("/");
						}}
				
						className={classes.button}
					>
						Features
					</Button>
				</li> */}
				<li>
					<Button
						onClick={() => {
							ScrollToView("plans");
							history.push("/");
						}}
						className={classes.button + " button"}
					>
						Pricing
					</Button>
				</li>
				<li>
					<Button
						onClick={() => {
							ScrollToView("downloads");
							history.push("/");
						}}
						className={classes.button + " button"}
					>
						Downloads
					</Button>
				</li>
				<li>
					<Button
						onClick={() => {
							ScrollToView("about");
							history.push("/");
						}}
						className={classes.button + " button"}
					>
						About
					</Button>
				</li>
			</ul>
		</div>
	);
};

export default Navigation;